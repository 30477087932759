import React, { useContext, useState, useEffect, useReducer, useRef, useMemo } from 'react'
import { useAtom } from 'jotai'
import dayjs from 'dayjs'
import Slider from 'react-slick'

import { userMenuAtom } from '~atoms'
import { useMediaCardData } from '~hooks'
import { getUser, getAgeGroupSlug, isRelatedArticle, hasBeenSeen } from '~utilities'

import { MediaCard } from '~components/shared'

const NewAndTrending = ({ className }) => {
  const _ = require(`lodash`)
  const [isUserMenuOpen] = useAtom(userMenuAtom)
  const [, forceUpdate] = useReducer(x => x + 1, 0)
  useEffect(() => {
    forceUpdate()
  }, [isUserMenuOpen])

  const [isClient, setIsClient] = useState(false)
  const [shuffledArticles, setShuffledArticles] = useState([])

  const user = getUser()
  const children = user && user?.['custom:childrenDOBs'] ? JSON.parse(user['custom:childrenDOBs']) : null
  const interests = user && user?.['custom:topicInterests'] ? JSON.parse(user['custom:topicInterests']) : null
  const articlesSeen = user && user?.['custom:articlesSeen'] ? JSON.parse(user['custom:articlesSeen']) : null

  const articles = useMediaCardData()
  let filteredArticles = articles

  if (children && children.length > 0) {
    let userAgeGroups = []
    children.map(child => {
      let ageGroup = dayjs().diff(child, 'months') |> getAgeGroupSlug
      userAgeGroups = userAgeGroups.concat([ageGroup])
    })
    filteredArticles = filteredArticles.filter(article => isRelatedArticle(article, userAgeGroups))
  }

  if (interests && interests.length > 0) {
    let userTopics = []
    interests.map(interest => {
      userTopics = userTopics.concat([interest])
    })
    filteredArticles = filteredArticles.filter(article => isRelatedArticle(article, userTopics))
  }

  if (articlesSeen && articlesSeen.length > 0) {
    filteredArticles = filteredArticles.filter(article => !hasBeenSeen(article, articlesSeen))
  }

  const slider = useRef(null)
  const settings = useMemo(() => {
    return {
      arrows: false,
      dots: false,
      infinite: true,
      speed: 400,
      slidesToShow: 3,
      slidesToScroll: 3,
      easing: 'ease',
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    }
  }, [])

  useEffect(() => {
    const shuffleArticles = unshuffledArticles => {
      setShuffledArticles(unshuffledArticles |> _.shuffle)
    }
    shuffleArticles(!user || filteredArticles.length === 0 ? articles : filteredArticles)
    setIsClient(true)
  }, [isUserMenuOpen]) // eslint-disable-line react-hooks/exhaustive-deps

  const toxicStress = _.find(shuffledArticles, {'slug': 'how-toxic-stress-can-impact-children'})

  if (isClient) {
    return (
      <div id='new-and-trending' className={className}>
        <Slider ref={slider} {...settings} className='w-full'>
          <div key={toxicStress.id} className='flex lg:w-1/3 p-4'>
            <MediaCard image={toxicStress.image} title={toxicStress.title} caption={toxicStress.caption} url={toxicStress.url} type={toxicStress.type} cta />
          </div>
          {shuffledArticles.slice(0, 8).map(({ id, image, title, caption, url, type }) => (
            <div key={id} className='flex lg:w-1/3 p-4'>
              <MediaCard image={image} title={title} caption={caption} url={url} type={type} cta />
            </div>
          ))}
        </Slider>
        <div className='relative flex justify-end items-center my-8'>
          <div>
            <button
              onClick={() => slider.current.slickPrev()}
              className='icon-large bg-green border border-green hover:bg-white text-white group'
            >
              <em className='fas fa-caret-left group-hover:text-green' />
            </button>
            <button
              onClick={() => slider.current.slickNext()}
              className='icon-large ml-2 bg-green border border-green hover:bg-white text-white group'
            >
              <em className='fas fa-caret-right group-hover:text-green' />
            </button>
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <div id='new-and-trending' className='flex'>
        {articles.slice(0, 3).map(({ id, image, title, caption, url, type }) => (
          <div key={id} className='flex lg:w-1/3 p-4'>
            <MediaCard image={image} title={title} caption={caption} url={url} type={type} cta />
          </div>
        ))}
      </div>
    )
  }
}

export default NewAndTrending
